import get from 'lodash/get'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import React, { useCallback } from 'react'

import { Asset, AssetContextType } from '@avantstay/arriere-clients/dist/arriereBackoffice'

import { UploadArea } from '@new/__ui/UploadArea'
import { FilePreviewType } from '@new/__ui/UploadArea/_types'
import { FilePreviewReadMode } from '@new/__ui/UploadArea/components/FilePreviewReadMode'

import { createAsset } from '@utils/createAsset'
// eslint-disable-next-line import/no-cycle
import { jsonParseCustomFieldValue } from '@utils/customFields'

import { CustomFieldProjectionProps, CustomFieldTypeReturn, IMountComponentProps } from '../_types'
import { stringifyValue } from '../CustomField.utils'
import * as S from './Asset.styles'

export function createUploadService({
  isPublic,
  context,
}: {
  isPublic: boolean
  context: AssetContextType
}) {
  return (
    targetId: string,
    file: File,
    fileId: string,
    onUploadProgress?: (fileId: string) => (percentage: number) => void,
  ) =>
    createAsset({
      targetId,
      file,
      fileId,
      onUploadProgress,
      public: isPublic,
      context,
    })
}

export async function createCustomFieldAsset(
  targetId: string,
  file: File,
  fileId: string,
  onUploadProgress: (fileId: string) => (percentage: number) => void,
) {
  return createAsset({
    targetId,
    file,
    fileId,
    onUploadProgress,
    public: false,
    context: AssetContextType.customField,
  })
}

function ComponentReadMode({ customField }: IMountComponentProps) {
  const assets = get(customField, 'assets', []) as Array<Asset>

  return (
    <S.Root>
      {assets.map((it, id) => (
        <FilePreviewReadMode file={it} key={id} filePreviewType={FilePreviewType.customField} />
      ))}
    </S.Root>
  )
}

function ComponentWriteMode({
  customField,
  inputProps,
  error,
  warning,
  isReadOnly,
  disabled,
}: IMountComponentProps) {
  const handleOnChange = useCallback(
    (files: Asset[]) => {
      inputProps?.onChange(files.map(it => it.id))
    },
    [inputProps],
  )

  if (isReadOnly) return <ComponentReadMode customField={customField} />

  return (
    <UploadArea
      initialFiles={customField.assets}
      onChange={handleOnChange}
      areaStyles={
        Boolean(error) || Boolean(warning) ? S.areaStylesByRestriction[customField.restriction] : {}
      }
      targetId={customField.id}
      uploadService={createCustomFieldAsset}
      filePreviewType={FilePreviewType.customField}
      disabled={disabled}
    />
  )
}

const parseValueToShow = (customField: CustomFieldProjectionProps) => {
  const value = jsonParseCustomFieldValue(customField)

  if (isEmpty(value)) {
    return []
  }

  return value
}

const parseValueToSave = (customField: CustomFieldProjectionProps) => {
  return isArray(customField.value)
    ? JSON.stringify(customField.value)
    : stringifyValue(customField.value)
}

export default {
  read: { Component: ComponentReadMode },
  write: { Component: ComponentWriteMode },
  parseValueToShow,
  parseValueToSave,
} as CustomFieldTypeReturn
