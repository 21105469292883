import React, { forwardRef, Ref, SVGProps } from 'react'

function _IconQuintess(props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="28" height="28" viewBox="4 7 20 15" fill="none" {...props} ref={ref}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.16752 10.073C8.82631 10.4607 8.14388 11.7201 8.14388 13.6557C8.14388 15.9623 9.53935 20.0872 14.5072 20.8704C16.6101 21.0234 18.0883 21.6139 18.5664 21.8915C14.4387 21.3305 12.2208 21.5748 10.2481 21.7921C9.93039 21.8271 9.61904 21.8614 9.30709 21.8915C9.48589 21.802 10.4663 21.331 11.8449 21.0527C10.7478 20.7656 8.94315 19.9456 8.0043 18.2621C6.65495 15.8426 6.70148 12.7252 9.16752 10.073Z"
        fill="white"
      />
      <path
        d="M19.0782 11.4689C17.3287 8.49099 14.3477 7.90163 13.0759 7.97918C14.8906 7.59143 18.9014 7.68138 20.4275 11.1431C21.9537 14.6049 20.0708 17.7658 18.9386 18.9135L18.9474 18.8992C19.3404 18.261 21.2484 15.1628 19.0782 11.4689Z"
        fill="white"
      />
    </svg>
  )
}

export const IconQuintess = forwardRef(_IconQuintess)
