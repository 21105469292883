import React, { forwardRef, Ref, SVGProps } from 'react'

function _IconGoogleTravel(props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="14" height="21" viewBox="0 0 14 21" fill="none" {...props} ref={ref}>
      <g clip-path="url(#clip0_58_2363)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.13406 0.899362C4.13406 0.660836 4.22813 0.43208 4.39558 0.263417C4.56302 0.094754 4.79013 0 5.02693 0L9.84844 0C10.0852 0 10.3123 0.094754 10.4798 0.263417C10.6472 0.43208 10.7413 0.660836 10.7413 0.899362V5.75592C10.7416 5.8742 10.7187 5.99137 10.6739 6.10072C10.6292 6.21008 10.5635 6.30947 10.4805 6.3932C10.3976 6.47693 10.2991 6.54336 10.1906 6.58869C10.0821 6.63402 9.96586 6.65735 9.84844 6.65735H5.02693C4.90951 6.65735 4.79323 6.63402 4.68477 6.58869C4.57631 6.54336 4.47779 6.47693 4.39485 6.3932C4.31191 6.30947 4.24619 6.21008 4.20144 6.10072C4.15669 5.99137 4.13379 5.8742 4.13406 5.75592V0.899362ZM5.74123 1.52995V4.90287H9.0894V1.52995H5.74123ZM4.06715 21C3.73605 21 3.41238 20.9011 3.13708 20.7158C2.86178 20.5305 2.64721 20.2672 2.5205 19.959C2.39379 19.6509 2.36064 19.3119 2.42523 18.9848C2.48983 18.6577 2.64927 18.3572 2.88339 18.1214C3.11752 17.8856 3.41581 17.725 3.74055 17.6599C4.06529 17.5948 4.40189 17.6282 4.70779 17.7559C5.01369 17.8835 5.27515 18.0996 5.4591 18.3769C5.64305 18.6542 5.74123 18.9802 5.74123 19.3137C5.74123 19.5352 5.69793 19.7545 5.6138 19.959C5.52967 20.1636 5.40636 20.3495 5.2509 20.5061C5.09545 20.6627 4.9109 20.7869 4.70779 20.8716C4.50468 20.9564 4.28699 21 4.06715 21ZM10.7635 21C10.4324 21 10.1087 20.9011 9.83341 20.7158C9.55811 20.5305 9.34353 20.2672 9.21683 19.959C9.09012 19.6509 9.05697 19.3119 9.12156 18.9848C9.18616 18.6577 9.3456 18.3572 9.57972 18.1214C9.81385 17.8856 10.1121 17.725 10.4369 17.6599C10.7616 17.5948 11.0982 17.6282 11.4041 17.7559C11.71 17.8835 11.9715 18.0996 12.1554 18.3769C12.3394 18.6542 12.4376 18.9802 12.4376 19.3137C12.4376 19.761 12.2612 20.1899 11.9472 20.5061C11.6333 20.8223 11.2075 21 10.7635 21Z"
          fill="#1967D2"
        />
        <path
          d="M12.3928 3.91211H2.48217C1.59455 3.91211 0.875 4.63689 0.875 5.53096V17.6271C0.875 18.5211 1.59455 19.2459 2.48217 19.2459H12.3928C13.2804 19.2459 14 18.5211 14 17.6271V5.53096C14 4.63689 13.2804 3.91211 12.3928 3.91211Z"
          fill="#4285F4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.875001 5.53136V17.6271C0.874781 17.7913 0.899421 17.9545 0.948073 18.1113L7.44325 11.5685L0.957105 5.02234C0.903013 5.18653 0.875297 5.35836 0.875001 5.53136Z"
          fill="#FBBC04"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.42695 11.6016L7.45979 11.5685L7.49263 11.6016H7.42695ZM0.972412 5.02236C1.07864 4.69924 1.28324 4.41806 1.55716 4.21875C1.83108 4.01945 2.16039 3.91215 2.4983 3.91211H12.4106C12.7501 3.91214 13.0808 4.02042 13.3554 4.22142C13.6301 4.42242 13.8345 4.70584 13.9394 5.03104L7.45979 11.5569L0.972412 5.02236Z"
          fill="#EA4335"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.41913 11.5565L7.45198 11.5896L7.48441 11.5565H7.41913ZM0.9646 18.1361C1.07085 18.4591 1.27541 18.7402 1.54924 18.9395C1.82306 19.1388 2.15225 19.2462 2.49007 19.2464H12.4016C12.7409 19.246 13.0715 19.1375 13.3459 18.9365C13.6203 18.7354 13.8246 18.4521 13.9295 18.127L7.45156 11.6016L0.9646 18.1361Z"
          fill="#34A853"
        />
        <path
          d="M7.43748 14.7943C9.21271 14.7943 10.6518 13.3447 10.6518 11.5566C10.6518 9.76842 9.21271 8.31885 7.43748 8.31885C5.66225 8.31885 4.22314 9.76842 4.22314 11.5566C4.22314 13.3447 5.66225 14.7943 7.43748 14.7943Z"
          fill="#4285F4"
        />
        <path
          d="M11.2872 10.8948C11.3373 11.1641 11.3624 11.4375 11.3624 11.7115C11.3624 13.9336 9.88452 15.5136 7.64885 15.5136C7.1384 15.5189 6.63197 15.4222 6.15885 15.2291C5.68573 15.0361 5.25532 14.7504 4.8925 14.3887C4.52968 14.027 4.24166 13.5964 4.04509 13.1219C3.84852 12.6473 3.74731 12.1382 3.74731 11.624C3.74731 11.1099 3.84852 10.6008 4.04509 10.1262C4.24166 9.65169 4.52968 9.22111 4.8925 8.8594C5.25532 8.4977 5.68573 8.21204 6.15885 8.01894C6.63197 7.82585 7.1384 7.72916 7.64885 7.73446C8.6932 7.73446 9.56596 8.12067 10.2351 8.74795L9.14436 9.84455V9.84124C8.73836 9.45214 8.22357 9.25283 7.64803 9.25283C6.37132 9.25283 5.33436 10.337 5.33436 11.6209C5.33436 12.9049 6.37132 13.9911 7.64803 13.9911C8.80609 13.9911 9.59428 13.325 9.75644 12.4111H7.64844V10.8948H11.2872Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_58_2363">
          <rect width="13.125" height="21" fill="white" transform="translate(0.875)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const IconGoogleTravel = forwardRef(_IconGoogleTravel)
