import arriereBackoffice, {
  BookingConnection,
  BookingLeadSource,
  BookingPeriodReference,
  BookingStatus,
  DocumentRequest,
  LeadSource,
  Pagination,
  PaginationInput,
  ServiceRequestStatusFilter,
  Status,
  UUID,
} from '@avantstay/arriere-clients/dist/arriereBackoffice'
import arrierePublic from '@avantstay/arriere-clients/dist/arrierePublic'

import type {
  AlterBookingVariables,
  IUpdateBookingParams,
  PreviewAlterDatesVariables,
} from '@new/domains/bookings/_types'
import type { CancelReservationActionProps } from '@new/domains/bookings/CancelBooking/CancelBooking.types'
import type {
  GetBookingConflictsParams,
  UpdateBookingConflictNote,
} from '@new/domains/bookings/screens/bookingConflicts/BookingConflicts.types'
import type {
  AlterBookingDetailsMutation,
  BookingHomeReadyForCheckIn,
  UpdateBookingHomeReadyForCheckIn,
} from '@new/domains/bookings/screens/bookingInfo/BookingInfo.types'
import type { GetBookingMessagesProps } from '@new/domains/bookings/screens/bookingMessages/BookingMessages.types'
import type { BookingFolioChangeHistory } from '@new/domains/bookings/screens/bookingPayment/actionPanels/ItemHistory/ItemHistory.types'
import type {
  BookingAccountingResolutionCenterFilterParams,
  CancelBookingWithRefundsProps,
} from '@new/domains/bookings/stores/_types'
// eslint-disable-next-line import/no-cycle
import { API_MAX_USAGE_INFINITY_VALUE } from '@new/domains/bookingsTapeChart/BookingsTapeChart.constants'
import type { BookingsForTapeChartProps } from '@new/domains/bookingsTapeChart/BookingsTapeChart.types'

import type { RefundMethodView } from '@arriere/types/BookingAdjustment.types'
import { BookingInfoArgs } from '@arriere/types/BookingInfo.types'

export default {
  createRentalAgreement(bookingId: string) {
    return arriereBackoffice.mutations.contract({
      createRentalAgreement: { __args: { bookingId }, id: true },
    })
  },
  resendRentalAgreement(contractId: string) {
    return arriereBackoffice.mutations.contract({
      resendRentalAgreement: { __args: { contractId }, id: true },
    })
  },
  async getBookingCases(bookingId: string) {
    return arriereBackoffice.queries
      .cases({
        __alias: 'getBookingCases',
        bookingCases: {
          __args: { bookingId },
          cases: {
            workOrderId: true,
            taskType: true,
            subject: true,
            status: true,
            priority: true,
            caseOwner: {
              name: true,
              email: true,
              phone: true,
            },
            inStayGuestIssue: true,
            dueDate: true,
            createdAt: true,
            createdBy: {
              name: true,
            },
            caseNumber: true,
            caseSfdcId: true,
          },
          workOrders: {
            id: true,
            workOrderType: true,
            taskType: true,
            status: true,
            priority: true,
            vendor: {
              name: true,
            },
            workers: {
              fullName: true,
              phone: true,
            },
            inStayGuestIssue: true,
            stats: {
              dueDate: true,
            },
            createdAt: true,
            sfdcCaseNumber: true,
            caseSfdcId: true,
          },
        },
      })
      .then(results => {
        return results.bookingCases
      })
      .catch(errors => {
        throw Error(errors[0]?.message)
        return undefined
      })
  },
  async bookingContracts(bookingId: string) {
    return arriereBackoffice.queries.booking({
      __alias: 'getBookingContracts',
      __args: { bookingId },
      id: true,
      emails: true,
      contracts: {
        id: true,
        bookingId: true,
        additionalData: true,
        rentalAgreement: true,
        status: true,
        createdAt: true,
        signerEmail: true,
        signingUrl: true,
      },
    })
  },

  cancelRentalAgreement(contractId: string) {
    return arriereBackoffice.mutations.contract({
      cancelRentalAgreement: {
        __args: { contractId },
        id: true,
      },
    })
  },

  async getBookingBalanceSummary(bookingId: string) {
    return arriereBackoffice.queries
      .booking({
        __alias: 'getBookingBalanceSummary',
        __args: {
          bookingId,
        },
        folio: {
          summary: {
            balance: true,
            deposit: {
              balance: true,
            },
            deposits: { balance: true, total: true, paid: true },
            isPaid: true,
            paid: {
              total: true,
              credits: true,
            },
            paymentBalanceStatus: true,
            total: true,
            value: true,
            valueNightsAndFees: true,
          },
        },
      })
      .then(response => response?.folio.summary)
  },

  async searchTermBookings({
    filter,
    pagination,
  }: {
    filter?: string
    pagination?: PaginationInput
  }) {
    return arriereBackoffice.queries.bookings({
      __alias: 'searchTermBookings',
      __args: {
        filter,
        pagination,
      },
      pages: true,
      results: {
        id: true,
        status: true,
        hash: true,
        period: {
          start: true,
          end: true,
        },
        detailedCheckIn: {
          effectiveTime: true,
        },
        detailedCheckOut: {
          effectiveTime: true,
        },
        source: true,
        guestName: true,
        guests: true,
        home: {
          id: true,
          name: true,
          links: {
            listings: {
              leadSource: true,
              url: true,
            },
            manual: true,
          },
          region: {
            name: true,
          },
        },
      },
    })
  },

  async getBookingsForTapeChart({
    filter,
    from,
    to,
    regionIds,
    homeIds,
    status,
    securityDepositOnHold,
  }: BookingsForTapeChartProps) {
    return arriereBackoffice.queries.bookings({
      __alias: 'getBookingsForTapeChart',
      __args: {
        filter,
        from,
        to,
        regionIds,
        homeIds,
        status: status || [
          BookingStatus.booked,
          BookingStatus.checkedIn,
          BookingStatus.checkedOut,
          BookingStatus.modified,
          BookingStatus.noShow,
          BookingStatus.requested,
        ],
        securityDepositOnHold,
        periodReference: BookingPeriodReference.period,
        pagination: { page: 1, size: API_MAX_USAGE_INFINITY_VALUE },
      },
      results: {
        id: true,
        activeServicesCount: true,
        folio: {
          summary: {
            total: true,
            paid: {
              total: true,
            },
            paymentBalanceStatus: true,
          },
        },
        source: true,
        hash: true,
        homeId: true,
        guestName: true,
        securityDepositStatus: true,
        period: {
          start: true,
          end: true,
        },
        home: {
          links: {
            listings: {
              leadSource: true,
              url: true,
            },
            manual: true,
          },
        },
        notes: true,
        issues: true,
        checkIn: true,
        checkOut: true,
        detailedCheckIn: {
          custom: {
            time: true,
          },
        },
        detailedCheckOut: {
          custom: {
            time: true,
          },
        },
        customFields: {
          __args: { tags: [] },
          fields: {
            id: true,
            value: true,
          },
        },
      },
    })
  },

  async getBookingById(bookingId?: string) {
    return bookingId === undefined
      ? undefined
      : arriereBackoffice.queries.booking
          .raw({
            __alias: 'getBookingById',
            __args: {
              bookingId,
            },
            id: true,
            hash: true,
            source: true,
            paymentPlan: {
              value: true,
            },
            bookingGroupName: true,
            periodChanges: true,
            relocations: true,
            userId: true,
            guestName: true,
            homeId: true,
            emails: true,
            phones: true,
            idVerificationState: {
              required: true,
              passed: true,
            },
            appDownloads: true,
            acquisition: {
              isAcquisition: true,
              partner: true,
              partnerBookingId: true,
              importedOn: true,
              acquisitionType: true,
              acquisitionPhase: true,
            },
            period: {
              start: true,
              end: true,
            },
            address: {
              regionId: true,
              address: true,
              city: true,
              zipCode: true,
            },
            tags: {
              bookingTags: {
                short: true,
              },
              guestsTags: {
                short: true,
              },
            },
            updatedAt: true,
            guestId: true,
            couponCode: true,
            guests: true,
            status: true,
            notes: true,
            reservationType: {
              id: true,
              name: true,
              externalName: true,
              isRevenueGenerating: true,
              isOwnerRelated: true,
            },
            heardAboutUs: true,
            customFields: {
              __args: { tags: [] },
              section: {
                id: true,
                name: true,
                readOnly: true,
              },
              fields: {
                id: true,
                kind: true,
                value: true,
                name: true,
                options: true,
                restriction: true,
                assets: {
                  id: true,
                  fileName: true,
                  size: true,
                  createdAt: true,
                  url: true,
                },
                userAllowedActions: true,
                requiredPrivileges: {
                  name: true,
                  category: true,
                  action: true,
                },
              },
            },
            services: {
              requests: {
                __args: { status: ServiceRequestStatusFilter.all },
                id: true,
                name: true,
                expiresAt: true,
                data: {
                  dateTime: true,
                  dates: true,
                  date: true,
                  quantity: true,
                  name: true,
                },
                status: true,
                paymentInfo: {
                  status: true,
                  amount: true,
                },
              },
            },
            reservationId: true,
            aboutUsDetails: true,
            attachments: {
              name: true,
              url: true,
            },
            folio: {
              summary: {
                valueNightsAndFees: true,
                total: true,
                balance: true,
                isPaid: true,
                paymentBalanceStatus: true,
                paid: {
                  total: true,
                  credits: true,
                },
                value: true,
                deposit: {
                  balance: true,
                },
              },
            },
            issues: true,
            cancellationInfo: {
              reason: true,
              source: true,
              stayCycle: true,
              note: true,
              author: {
                kind: true,
                name: true,
              },
              createDate: true,
              appliedCancellationPolicy: {
                cancellationPolicyId: true,
              },
            },
            guestList: {
              email: true,
              name: true,
              phone: true,
              roomId: true,
              personId: true,
              credits: true,
              tags: {
                tag: {
                  id: true,
                  name: true,
                  vipLevel: true,
                },
              },
            },
            detailedCheckIn: {
              originalTime: true,
              effectiveTime: true,
              custom: {
                time: true,
                status: true,
              },
            },
            detailedCheckOut: {
              originalTime: true,
              effectiveTime: true,
              custom: {
                time: true,
                status: true,
              },
            },
            checkInType: true,
            links: {
              manual: true,
              reservationHub: true,
              zendeskLink: true,
            },
            contracts: {
              id: true,
              bookingId: true,
              detailsUrl: true,
              additionalData: true,
              signingUrl: true,
              filesUrl: true,
              rentalAgreement: true,
              status: true,
              createdAt: true,
            },
            confirmationId: true,
            createdAt: true,
            marketingInfo: {
              adults: true,
              children: true,
              infants: true,
              trafficSource: true,
              purposeOfStay: true,
              notes: true,
            },
            pets: true,
            lockCode: {
              code: true,
            },
            protection: {
              status: true,
              orderId: true,
              price: true,
              time: true,
            },
            otaBookingInfo: {
              ota: true,
              otaBookingId: true,
              channel: true,
              channelBookingId: true,
              channelAccount: {
                channelId: true,
                name: true,
              },
            },
            hotelRoomType: {
              hotelId: true,
              roomKind: true,
              id: true,
            },
          })
          .then(async ({ data, errors }) => {
            if (errors?.length) {
              throw new Error(errors[errors?.length - 1]?.message)
            }

            const home = await getBookingHomeInfo({ bookingId })

            return { ...data, home }
          })
  },

  async getBookingItemHistory(
    bookingId: string,
    itemId: string,
  ): Promise<BookingFolioChangeHistory[]> {
    return arriereBackoffice.queries
      .booking({
        __alias: 'getBookingItemHistory',
        __args: {
          bookingId,
        },
        folio: {
          changes: {
            __args: { itemId },
            id: true,
            itemId: true,
            kind: true,
            item: {
              id: true,
              kind: true,
              title: true,
              total: true,
              createdAt: true,
            },
            adjustment: {
              amount: true,
              reason: true,
            },
            cancellation: {
              time: true,
              reason: true,
            },
            refund: {
              total: true,
              reason: true,
            },
            author: {
              kind: true,
              name: true,
            },
            time: true,
            balance: true,
          },
        },
      })
      .then(response => response?.folio?.changes)
  },

  async getBookingHomeReadyForCheckIn(bookingId: string): Promise<BookingHomeReadyForCheckIn> {
    return arriereBackoffice.queries
      .booking({
        __alias: 'getBookingHomeReadyForCheckIn',
        __args: {
          bookingId,
        },
        homeReadyForCheckInStatus: {
          author: true,
          managedUnitId: true,
          status: true,
          reason: true,
          note: true,
          workOrderId: true,
          createdAt: true,
          relatedCase: {
            workOrderId: true,
            subject: true,
            casePriority: true,
            dueDate: true,
          },
        },
      })
      .then(home => home.homeReadyForCheckInStatus)
  },

  async updateBookingHomeReadyForCheckIn({ bookingId, status }: UpdateBookingHomeReadyForCheckIn) {
    return arriereBackoffice.mutations.booking({
      __alias: 'updateBookingHomeReadyForCheckIn',
      updateHomeReadyForCheckInStatus: {
        __args: {
          bookingId,
          status,
        },
      },
    })
  },

  async getWholesalePaymentOptions(): Promise<string[]> {
    return arriereBackoffice.queries.wholesalePaymentOptions(
      {} as any, // Need to fix the graphql generator that is broken for this case of query without args
    )
  },

  async getAdjustmentReasons() {
    return arriereBackoffice.queries.adjustmentReasons({
      __alias: 'getAdjustmentReasons',
      negativeAdjustment: {
        name: true,
        subitem: true,
      },
      positiveAdjustment: {
        name: true,
        subitem: true,
      },
    })
  },

  async getRefundReasons() {
    return arriereBackoffice.queries.refundReasons({
      __alias: 'getRefundReasons',
      __args: {},
      name: true,
      subitem: true,
      stayCycleTags: true,
    })
  },

  async marketingInfo(bookingId: string) {
    return arrierePublic.queries.bookingMarketingInfo({
      __alias: 'getMarketingInfo',
      __args: {
        bookingId,
      },
      adults: true,
      children: true,
      infants: true,
      notes: true,
      purposeOfStay: true,
      trafficSource: true,
    })
  },

  async getIdRequestsWithPersona({
    checkInAfter,
    checkInBefore,
    filter,
    status,
    pagination,
  }: {
    checkInAfter?: string
    checkInBefore?: string
    filter?: string
    status?: Status[]
    pagination?: PaginationInput
  }) {
    return arriereBackoffice.queries.bookingIdVerifications({
      __alias: 'getIdRequestsWithPersona',
      __args: {
        checkInAfter,
        checkInBefore,
        filter,
        status,
        pagination,
      },
      total: true,
      pages: true,
      paginationParams: {
        page: true,
        size: true,
      },
      results: {
        bookingHash: true,
        bookingCreatedAt: true,
        idVerificationStatus: true,
        idVerificationCreatedAt: true,
        mappedRisks: true,
        booking: {
          id: true,
          hash: true,
          confirmationId: true,
          guestName: true,
          guests: true,
          tier: true,
          period: {
            start: true,
            end: true,
          },
          address: {
            address: true,
            state: {
              name: true,
            },
            region: {
              name: true,
            },
            zipCode: true,
          },
          home: {
            name: true,
            region: {
              name: true,
            },
          },
          folio: {
            summary: {
              value: true,
              total: true,
              isPaid: true,
            },
          },
        },
      },
    })
  },

  async getReservationTypes() {
    return arriereBackoffice.queries.customFields({
      __alias: 'getReservationTypes',
      __args: {
        // Booking Reservation Types Custom Field Id
        customFieldSectionId: 'ebe53981-1aa2-425e-a3c5-9de6ad76cc3c',
      },
      id: true,
      name: true,
      defaultValue: true,
      values: {
        value: true,
      },
    })
  },

  async getBookingConflicts({
    bookingConflictSources,
    resolvedOnly,
    unResolvedOnly,
    pagination,
  }: GetBookingConflictsParams) {
    return arriereBackoffice.queries
      .bookingResolutions({
        __alias: 'getBookingConflicts',
        listConflicts: {
          __args: {
            bookingConflictSources,
            conflictsWithBookingsOnly: false,
            resolvedOnly,
            unResolvedOnly,
            pagination,
          },
          pages: true,
          total: true,
          results: {
            bookingId: true,
            bookingBlockId: true,
            createdAt: true,
            booking: {
              hash: true,
              createdAt: true,
              home: {
                id: true,
                name: true,
                region: {
                  name: true,
                },
              },
              source: true,
              guestName: true,
              period: {
                start: true,
                end: true,
              },
            },
            conflictBlock: {
              blockId: true,
              period: {
                start: true,
                end: true,
              },
              source: true,
              bookingId: true,
            },
            conflictBlockBooking: {
              hash: true,
              createdAt: true,
              home: {
                id: true,
                name: true,
                region: {
                  name: true,
                },
              },
              source: true,
              guestName: true,
              period: {
                start: true,
                end: true,
              },
            },
            id: true,
            homeId: true,
            notes: {
              note: true,
            },
            resolved: true,
            resolvedAt: true,
            updatedAt: true,
          },
        },
      })
      .then(bookingConflict => bookingConflict.listConflicts)
  },

  async getBookingReviews(bookingId: UUID) {
    return arriereBackoffice.queries
      .booking({
        __alias: 'getBookingReviews',
        __args: {
          bookingId,
        },
        reviews: {
          __args: {
            onlyActive: undefined,
          },
          id: true,
          source: true,
          privateFeedback: true,
          response: true,
          externalReviewId: true,
          createdAt: true,
          categoryReviews: {
            category: true,
            rating: true,
            comment: true,
          },
          booking: {
            guestName: true,
            guestId: true,
          },
          bookingPeriod: {
            start: true,
            end: true,
          },
        },
      })
      .then(({ reviews }) => reviews)
  },

  async getBookingMessages({
    bookingHash,
    searchTerm,
    sortOrder,
    status,
    templateType,
  }: GetBookingMessagesProps) {
    return arriereBackoffice.queries
      .bookings({
        __alias: 'getBookingMessages',
        __args: {
          filter: bookingHash,
        },
        results: {
          messagesSent: {
            __args: {
              query: searchTerm,
              sortOrder,
              status,
              templateKind: templateType,
            },
            id: true,
            additionalData: true,
            createdAt: true,
            emails: true,
            message: true,
            phones: true,
            person: {
              fullName: true,
            },
            status: true,
            subject: true,
            templateKind: true,
            templateName: true,
            textMessage: true,
          },
        },
      })
      .then(({ results }) => results[0].messagesSent || [])
  },

  // Mutations

  async updateBooking({ bookingId, fieldValues }: IUpdateBookingParams) {
    return arriereBackoffice.mutations.updateBookingCustomFieldValues({
      __args: {
        bookingId,
        fieldValues,
      },
    })
  },

  async ignoreContractValidation(bookingId: string) {
    return arriereBackoffice.mutations.booking({
      __alias: 'ignoreContractValidation',
      ignoreContractValidation: {
        __args: { bookingId },
      },
    })
  },

  async requireContractValidation(bookingId: string) {
    return arriereBackoffice.mutations.booking({
      __alias: 'requireContractValidation',
      requireContractValidation: {
        __args: { bookingId },
      },
    })
  },

  async refundBookingCharges({
    paymentsToRefund,
    reason,
  }: {
    paymentsToRefund: Array<{
      paymentId: string
      amount: number
    }>
    reason: string
  }) {
    return arriereBackoffice.mutations
      .payments({
        refundBookingCharges: {
          __args: {
            refunds: paymentsToRefund,
            reason,
          },
        },
      })
      .then(result => result.refundBookingCharges)
  },

  async refundPaymentSource(bookingId: string, methods: RefundMethodView[]) {
    return arriereBackoffice.mutations
      .payments({
        refundPaymentSource: {
          __args: {
            bookingId,
            methods,
          },
        },
      })
      .then(result => result?.refundPaymentSource)
  },

  /**
   * Returns the client_secret string to authorize payment with Stripe
   */
  async makeNewCardCharge({
    bookingId,
    amount,
    title,
  }: {
    bookingId: string
    amount: number
    title: string
  }) {
    return arriereBackoffice.mutations
      .payments({
        makeNewCardCharge: {
          __args: {
            bookingId,
            amount,
            title,
          },
        },
      })
      .then(result => result.makeNewCardCharge)
  },

  async makeBookingCardCharge({
    bookingId,
    amount,
    title,
    cardId,
  }: {
    bookingId: string
    amount: number
    title: string
    cardId: string
  }) {
    return arriereBackoffice.mutations
      .payments({
        makeBookingCardCharge: {
          __args: {
            bookingId,
            amount,
            title,
            cardId,
          },
        },
      })
      .then(result => result.makeBookingCardCharge)
  },

  async makePaymentLinkCharge({
    bookingId,
    amount,
    title,
    email,
  }: {
    bookingId: string
    amount: number
    title: string
    email: string
  }) {
    return arriereBackoffice.mutations.payments({
      makePaymentLinkCharge: {
        __args: {
          bookingId,
          amount,
          title,
          email,
        },
      },
    })
  },

  async makeSecurityDepositCharge({
    bookingId,
    amount,
    title,
  }: {
    bookingId: string
    amount: number
    title: string
  }) {
    return arriereBackoffice.mutations
      .payments({
        makeSecurityDepositCharge: {
          __args: {
            amount,
            bookingId,
            title,
          },
        },
      })
      .then(result => result.makeSecurityDepositCharge)
  },

  async makeWholesaleCharge({
    bookingId,
    amount,
    title,
    platform,
    time,
  }: {
    bookingId: string
    amount: number
    title: string
    platform: string
    time: string
  }) {
    return arriereBackoffice.mutations
      .payments({
        makeWholesaleCharge: {
          __args: {
            bookingId,
            amount,
            title,
            platform,
            time,
          },
        },
      })
      .then(result => result.makeWholesaleCharge)
  },

  async makeAchBankCharge({
    bookingId,
    amount,
    title,
    customerId,
  }: {
    bookingId: string
    amount: number
    title: string
    customerId: string
  }) {
    return arriereBackoffice.mutations
      .payments({
        makeBookingBankCharge: {
          __args: {
            bookingId,
            amount,
            title,
            customerId,
          },
        },
      })
      .then(result => result.makeBookingBankCharge)
  },

  async deleteBookingCharge({ paymentId }: { paymentId: string }) {
    return arriereBackoffice.mutations
      .payments({
        deleteBookingCharge: {
          __args: { paymentId },
        },
      })
      .then(result => result.deleteBookingCharge)
  },

  async approveIdDocumentRequest(documentId: string) {
    return arriereBackoffice.mutations
      .booking({
        approveDocument: {
          __args: { documentId },
        },
      })
      .then(result => result.approveDocument)
  },

  async rejectIdDocumentRequest(documentId: string) {
    return arriereBackoffice.mutations
      .booking({
        rejectDocument: {
          __args: { documentId },
        },
      })
      .then(result => result.rejectDocument)
  },

  async requestNewIdDocument(documentId: string) {
    return arriereBackoffice.mutations
      .booking({
        requestNewDocument: {
          __args: { documentId },
        },
      })
      .then(result => result.requestNewDocument)
  },

  async createDocumentMutation({ description, documentKind, extension, size }: DocumentRequest) {
    return arriereBackoffice.mutations
      .booking({
        createDocument: {
          __args: {
            documentRequest: {
              description,
              documentKind,
              extension,
              size,
            },
          },
          documentId: true,
          url: true,
        },
      })
      .then(result => result.createDocument)
  },

  async attachDocumentToBooking({
    bookingId,
    documentId,
  }: {
    bookingId: string
    documentId: string
  }) {
    return arriereBackoffice.mutations
      .booking({
        attachDocument: { __args: { bookingId, documentId } },
      })
      .then(result => result.attachDocument)
  },

  async approveIdWithPersona(bookingHash: string) {
    return arriereBackoffice.mutations.booking({
      approveId: {
        __args: { bookingHash },
      },
    })
  },

  async rejectIdWithPersona(bookingHash: string) {
    return arriereBackoffice.mutations.booking({
      rejectId: {
        __args: { bookingHash },
      },
    })
  },

  async requireIdVerification(bookingHash: string) {
    return arriereBackoffice.mutations.booking({
      __alias: 'requireIdVerification',
      requireIdVerification: {
        __args: { bookingHash },
      },
    })
  },

  async skipIdVerificationWithPersona(bookingHash: string) {
    return arriereBackoffice.mutations.booking({
      skipIdVerification: {
        __args: { bookingHash },
      },
    })
  },

  async requestNewIdWithPersona(bookingId: string) {
    return arriereBackoffice.mutations.resendDocumentRequest({
      __args: { bookingId },
    })
  },

  async addNameToBlackList(name: string) {
    return arriereBackoffice.mutations.addNameToBlacklist({
      __args: { name },
    })
  },

  async addPhoneToBlackList(phone: string) {
    return arriereBackoffice.mutations.addPhoneToBlacklist({
      __args: { phone },
    })
  },

  async addEmailToBlackList(email: string) {
    return arriereBackoffice.mutations.addToBlacklist({
      __args: { email },
    })
  },

  async resendDocumentRequestEmail(bookingId: string) {
    return arriereBackoffice.mutations.resendDocumentRequest({
      __args: { bookingId },
    })
  },

  async resendCheckInInstructionsEmail(id: string) {
    return arriereBackoffice.mutations.resendCheckInInstructions({
      __args: { bookingId: id },
    })
  },

  async pushChangesToKustomer(bookingId: string): Promise<{ kustomerPushChanges: boolean }> {
    return arriereBackoffice.mutations.booking({
      __alias: 'pushChangesToKustomer',
      kustomerPushChanges: {
        __args: { bookingId },
      },
    })
  },

  async pushChangesToZendesk(bookingId: string): Promise<{ zendeskPushChanges: boolean }> {
    return arriereBackoffice.mutations.booking({
      __alias: 'pushChangesToZendesk',
      zendeskPushChanges: {
        __args: { bookingId },
      },
    })
  },

  async getAccountingAuditStatuses(bookingConnection: BookingConnection) {
    return arriereBackoffice.queries.accountingAuditStatuses({
      __alias: 'getAccountingAuditStatuses',
      __args: { bookingConnection },
      value: true,
      setBy: true,
      setAt: true,
    })
  },

  async unresolvedAccountingIssuesPaginated(
    filterParams: BookingAccountingResolutionCenterFilterParams,
    pagination: Pagination,
  ) {
    const { errorKind, transactionType, dateRange, byKeyword, bookingSources } = filterParams

    return arriereBackoffice.queries.accounting({
      __alias: 'unresolvedAccountingIssuesPaginated',
      arc: {
        __args: {
          byKeyword,
          dateRange,
          errorKind,
          transactionType,
          bookingSources: bookingSources as LeadSource[] | undefined,
        },
        issues: {
          __args: {
            pagination,
          },
          total: true,
          pages: true,
          paginationParams: {
            page: true,
            size: true,
          },
          results: {
            source: true,
            amount: true,
            confirmationId: true,
            connection: true,
            crossReferenceCode: true,
            guestName: true,
            hash: true,
            homeName: true,
            id: true,
            kind: true,
            links: {
              netsuite: {
                customer: true,
                customerDepositOrPayment: true,
                customerRefund: true,
                location: true,
                salesOrder: true,
              },
              stripe: {
                charge: true,
                searchByBookingHash: true,
                searchByConfirmationId: true,
              },
              voyage: {
                transactions: true,
                timeline: true,
              },
            },
            message: true,
            origin: true,
            originalBtrKind: true,
            period: {
              end: true,
              start: true,
            },
            regionName: true,
            reservationId: true,
            status: true,
            statusSetAt: true,
            statusSetBy: true,
            transactionDate: true,
          },
        },
        issuesInformation: {
          count: {
            bookings: true,
            payments: true,
            refunds: true,
            invoices: true,
          },
          originalBtrKinds: true,
        },
      },
    })
  },
  async getAccountingResolutionCenterData(
    filterParams: BookingAccountingResolutionCenterFilterParams,
    pagination: Pagination,
  ) {
    const {
      errorKind,
      transactionType,
      dateRange,
      byKeyword,
      bookingSources,
      hasIssues,
      status,
      paymentKind,
      origin,
    } = filterParams

    return arriereBackoffice.queries.accountingResolutionCenter({
      __alias: 'accountingResolutionCenter',
      __args: {
        byKeyword,
        dateRange,
        errorKind,
        transactionType,
        bookingSources: bookingSources as BookingLeadSource[] | undefined,
        pagination,
        hasIssues,
        status,
        paymentKind,
        origin,
      },
      arc: {
        issues: {
          total: true,
          pages: true,
          paginationParams: {
            page: true,
            size: true,
          },
          results: {
            checkIn: true,
            checkOut: true,
            paymentKind: true,
            source: true,
            amount: true,
            voyageAmount: true,
            confirmationId: true,
            crossReferenceCode: true,
            guestName: true,
            hash: true,
            homeName: true,
            kind: true,
            links: {
              netsuite: {
                salesOrder: true,
              },
              stripe: {
                charge: true,
                searchByBookingHash: true,
                searchByConfirmationId: true,
              },
              voyage: {
                transactions: true,
                timeline: true,
              },
            },
            message: true,
            origin: true,
            originalBalanceTransactionKind: true,
            period: {
              end: true,
              start: true,
            },
            regionName: true,
            reservationId: true,
            status: true,
            transactionDate: true,
            netsuitePayoutId: true,
            netsuitePayoutUrl: true,
            payoutRemoteId: true,
          },
        },
        issuesInformation: {
          count: {
            bookings: true,
            payments: true,
            refunds: true,
            invoices: true,
            paymentsAndRefunds: true,
          },
          originalBtrKinds: true,
          origins: true,
          statusCount: true,
          statuses: true,
        },
      },
    })
  },
  async accountingTimelineForBooking(bookingId: string) {
    return arriereBackoffice.queries.accountingTimelineForBooking({
      __alias: 'accountingTimelineForBooking',
      __args: {
        bookingId,
      },
      customers: {
        connection: true,
        status: true,
        createdAt: true,
        isResolution: true,
        kind: true,
        links: {
          netsuite: {
            customer: true,
            customerDepositOrPayment: true,
            customerRefund: true,
            location: true,
            salesOrder: true,
          },
          stripe: {
            charge: true,
            searchByBookingHash: true,
            searchByConfirmationId: true,
          },
          voyage: {
            transactions: true,
            timeline: true,
          },
        },
        message: true,
        amount: true,
        transactionDate: true,
        operationKind: true,
        originalBtrKind: true,
        origin: true,
      },
      customerDeposits: {
        connection: true,
        status: true,
        createdAt: true,
        isResolution: true,
        kind: true,
        links: {
          netsuite: {
            salesOrder: true,
            customerDepositOrPayment: true,
            customerRefund: true,
            customer: true,
            location: true,
          },
          stripe: {
            charge: true,
            searchByBookingHash: true,
            searchByConfirmationId: true,
          },
          voyage: {
            transactions: true,
            timeline: true,
          },
        },
        message: true,
        amount: true,
        transactionDate: true,
        operationKind: true,
        originalBtrKind: true,
        origin: true,
      },
      customerPayments: {
        connection: true,
        status: true,
        createdAt: true,
        isResolution: true,
        kind: true,
        links: {
          netsuite: {
            salesOrder: true,
            customerDepositOrPayment: true,
            customerRefund: true,
            customer: true,
            location: true,
          },
          stripe: {
            charge: true,
            searchByBookingHash: true,
            searchByConfirmationId: true,
          },
          voyage: {
            transactions: true,
            timeline: true,
          },
        },
        message: true,
        amount: true,
        transactionDate: true,
        operationKind: true,
        originalBtrKind: true,
        origin: true,
      },
      customerRefunds: {
        connection: true,
        status: true,
        createdAt: true,
        isResolution: true,
        kind: true,
        links: {
          netsuite: {
            salesOrder: true,
            customerDepositOrPayment: true,
            customerRefund: true,
            customer: true,
            location: true,
          },
          stripe: {
            charge: true,
            searchByBookingHash: true,
            searchByConfirmationId: true,
          },
          voyage: {
            transactions: true,
            timeline: true,
          },
        },
        message: true,
        amount: true,
        transactionDate: true,
        operationKind: true,
        originalBtrKind: true,
        origin: true,
      },
      locations: {
        connection: true,
        status: true,
        createdAt: true,
        isResolution: true,
        kind: true,
        links: {
          netsuite: {
            customer: true,
            customerDepositOrPayment: true,
            customerRefund: true,
            location: true,
            salesOrder: true,
          },
          stripe: {
            charge: true,
            searchByBookingHash: true,
            searchByConfirmationId: true,
          },
          voyage: {
            transactions: true,
            timeline: true,
          },
        },
        message: true,
        amount: true,
        transactionDate: true,
        operationKind: true,
        originalBtrKind: true,
        origin: true,
      },
      salesOrders: {
        connection: true,
        status: true,
        createdAt: true,
        isResolution: true,
        kind: true,
        links: {
          netsuite: {
            salesOrder: true,
            customerDepositOrPayment: true,
            customerRefund: true,
            customer: true,
            location: true,
          },
          stripe: {
            charge: true,
            searchByBookingHash: true,
            searchByConfirmationId: true,
          },
          voyage: {
            transactions: true,
            timeline: true,
          },
        },
        message: true,
        amount: true,
        transactionDate: true,
        operationKind: true,
        originalBtrKind: true,
        origin: true,
      },
    })
  },

  async getAccountingTimeline(bookingId: string) {
    return arriereBackoffice.queries
      .accountingTimeline({
        __alias: 'accountingTimeline',
        __args: {
          bookingId,
        },
        results: {
          issueType: true,
          transactionDate: true,
          amount: true,
          originalBalanceTransactionKind: true,
          origin: true,
          status: true,
          transactionType: true,
          links: {
            netsuite: { salesOrder: true },
            voyage: { transactions: true, timeline: true },
          },
          bookingId: true,
          transactionId: true,
          bookingHash: true,
        },
      })
      .then(({ results }) => results)
  },
  async triggerTransactionNetSuiteSync({ transactionId }: { transactionId: string }) {
    return arriereBackoffice.mutations.accountingTimeLine
      .raw({
        __alias: 'reSyncTransaction',
        reSyncTransaction: {
          __args: {
            transactionId,
          },
        },
      })
      .then(({ data }) => data?.reSyncTransaction)
  },

  async triggerBookingNetSuiteSync({ bookingId }: { bookingId: string }) {
    return arriereBackoffice.mutations.accountingTimeLine.raw({
      __alias: 'reSyncBooking',
      reSyncBooking: {
        __args: {
          bookingId,
        },
      },
    })
  },

  async cancelBooking({ bookingId, reason, note, stayCycle }: CancelReservationActionProps) {
    return arriereBackoffice.mutations.cancelBooking.raw({
      __args: {
        bookingId,
        reason,
        note,
        stayCycle,
      },
    })
  },

  async resendMessage({
    personNotifyLogIds,
    phones,
    emails,
  }: {
    personNotifyLogIds: UUID[]
    phones?: string[]
    emails?: string[]
  }) {
    return arriereBackoffice.mutations.resendMessage({
      __args: {
        personNotifyLogIds,
        phones,
        emails,
      },
    })
  },

  async sendBookingReceipt(bookingId: UUID, email: string) {
    return arriereBackoffice.mutations.booking.raw({
      sendBookingReceipt: {
        __args: {
          bookingId,
          email,
        },
      },
    })
  },

  async updateBookingConflictNotes({ bookingConflictId, notes }: UpdateBookingConflictNote) {
    return arriereBackoffice.mutations.bookingResolutions({
      updateNotes: {
        __args: {
          id: bookingConflictId,
          notes,
        },
        notes: {
          note: true,
        },
      },
    })
  },

  async alterBooking({
    bookingId,
    homeId,
    newRange,
    nightPrices,
    taxExemption,
    newFees,
  }: AlterBookingVariables) {
    return arriereBackoffice.mutations.booking.raw({
      __alias: 'alterBooking',
      alterBooking: {
        __args: {
          bookingId,
          homeId,
          newRange,
          nightPrices,
          taxExemption,
          newFees,
        },
      },
    })
  },

  async bookingFloorPrice(
    bookingId: UUID,
    newRange: { start: string; end: string },
    newHomeId?: UUID,
  ) {
    return arriereBackoffice.queries.bookingFloorPrice.memo({
      __alias: 'getBookingFloorPrice',
      __args: {
        bookingId,
        newRange,
        newHomeId,
      },
      date: true,
      floorPrice: true,
      regularPrice: true,
      orphanPrice: true,
    })
  },

  async checkConflicts() {
    return arriereBackoffice.mutations.bookingResolutions({
      checkConflicts: {
        newConflictsCount: true,
        unchangedConflictsCount: true,
        resolvedConflictsCount: true,
      },
    })
  },

  async reservationTypes() {
    return arriereBackoffice.queries.reservationTypes({
      id: true,
      name: true,
      isOwnerRelated: true,
      isRevenueGenerating: true,
    })
  },

  async alterDetails(values: AlterBookingDetailsMutation) {
    return arriereBackoffice.mutations.booking.raw({
      __alias: 'alterBookingDetails',
      alterDetails: {
        __args: {
          ...values,
        },
      },
    })
  },

  async updateCheckInInstructionsNotes(bookingId: string, notes?: string) {
    await arriereBackoffice.mutations.updateBookingCheckInNote({
      __alias: 'updateBookingCheckInNote',
      __args: { bookingId, note: notes },
    })
  },

  async alterBookingMainGuest(bookingId: string, newMainGuestId: string) {
    return arriereBackoffice.mutations.booking.raw({
      __alias: 'alterBookingMainGuest',
      alterMainGuest: {
        __args: {
          bookingId,
          newMainGuestId,
        },
      },
    })
  },
}

export const bookingModificationPossibility = async (bookingId: string) => {
  return arriereBackoffice.queries.bookingModificationPossibility
    .memoRaw({
      __alias: 'getBookingModificationPossibility',
      __args: {
        bookingId,
      },
      cancellationImpossibilityReason: true,
      cancellationPossible: true,
      modificationHotelRoomPossible: true,
      modificationHotelRoomImpossibilityReason: true,
      modificationImpossibilityReason: true,
      taxFeeOverrideImpossibilityReason: true,
      taxFeeOverridePossible: true,
      modificationPossibilities: {
        possible: true,
      },
    })
    .then(({ data, errors }) => {
      if (errors?.length) {
        throw new Error(errors[errors?.length - 1].message)
      }

      return data
    })
}

export const getBookingHomeInfo = async ({ bookingId, hash, confirmationId }: BookingInfoArgs) => {
  return arriereBackoffice.queries.booking
    .raw({
      __alias: 'getBookingHomeInfo',
      __args: {
        bookingId,
        hash,
        confirmationId,
      },
      home: {
        id: true,
        address: {
          address: true,
        },
        name: true,
        title: true,
        thumbnail: {
          url: true,
        },
        region: {
          id: true,
          name: true,
        },
        revenueModel: true,
        links: {
          manual: true,
          listings: {
            leadSource: true,
            url: true,
            kind: true,
          },
        },
        maxPets: true,
        maxOccupancy: true,
        econPropertyFinancialHealth: true,
        customFields: {
          __args: { tags: undefined },
          section: {
            id: true,
          },
          fields: {
            id: true,
            value: true,
            name: true,
          },
        },
        lifecycle: {
          listingStatus: true,
          riskLevel: true,
        },
      },
    })
    .then(({ data, errors }) => {
      if (!data && errors.length) {
        throw new Error(errors[errors.length - 1]?.message)
      }

      return data.home
    })
}

export const getBookingInfoById = async ({
  bookingId,
  hash,
  confirmationId,
  tags,
}: BookingInfoArgs) => {
  return arriereBackoffice.queries.booking
    .raw({
      __alias: 'getBookingInfoById',
      __args: {
        bookingId,
        hash,
        confirmationId,
      },
      id: true,
      hash: true,
      source: true,
      paymentPlan: {
        value: true,
      },
      bookingGroupName: true,
      periodChanges: true,
      userId: true,
      guestName: true,
      emails: true,
      phones: true,
      appDownloads: true,
      acquisition: {
        isAcquisition: true,
        partner: true,
        partnerBookingId: true,
        importedOn: true,
        acquisitionType: true,
        acquisitionPhase: true,
      },
      address: {
        regionId: true,
        address: true,
        city: true,
        zipCode: true,
      },
      tags: {
        bookingTags: {
          short: true,
        },
        guestsTags: {
          short: true,
        },
      },
      guestId: true,
      homeId: true,
      couponCode: true,
      period: {
        start: true,
        end: true,
      },
      guests: true,
      pets: true,
      marketingInfo: {
        adults: true,
        children: true,
        infants: true,
        purposeOfStay: true,
        trafficSource: true,
        notes: true,
      },
      reservationType: {
        id: true,
        name: true,
        externalName: true,
      },
      status: true,
      heardAboutUs: true,
      createdAt: true,
      updatedAt: true,
      confirmationId: true,
      reservationId: true,
      aboutUsDetails: true,
      attachments: {
        name: true,
        url: true,
      },
      lockCode: {
        code: true,
      },
      folio: {
        summary: {
          valueNightsAndFees: true,
          total: true,
          balance: true,
          isPaid: true,
          paymentBalanceStatus: true,
          paid: {
            total: true,
            credits: true,
          },
          value: true,
          deposit: {
            balance: true,
          },
        },
      },
      issues: true,
      idVerificationState: {
        required: true,
        passed: true,
      },
      guestList: {
        email: true,
        name: true,
        phone: true,
        roomId: true,
        personId: true,
        credits: true,
        tags: {
          tag: {
            id: true,
            name: true,
            vipLevel: true,
          },
        },
      },
      detailedCheckIn: {
        custom: { status: true, time: true },
        effectiveTime: true,
        originalTime: true,
      },
      detailedCheckOut: {
        custom: { status: true, time: true },
        effectiveTime: true,
        originalTime: true,
      },
      checkInType: true,
      customFields: {
        __args: { tags },
        section: {
          id: true,
          name: true,
          readOnly: true,
        },
        fields: {
          id: true,
          kind: true,
          value: true,
          name: true,
          options: true,
          restriction: true,
          assets: {
            id: true,
            fileName: true,
            size: true,
            createdAt: true,
            url: true,
          },
          userAllowedActions: true,
          requiredPrivileges: {
            name: true,
            category: true,
            action: true,
          },
        },
      },
      links: {
        manual: true,
        reservationHub: true,
        zendeskLink: true,
      },
      contracts: {
        id: true,
        bookingId: true,
        detailsUrl: true,
        additionalData: true,
        signingUrl: true,
        filesUrl: true,
        rentalAgreement: true,
        status: true,
        createdAt: true,
      },
      cancellationInfo: {
        reason: true,
        source: true,
        stayCycle: true,
        note: true,
        author: {
          kind: true,
          name: true,
        },
        createDate: true,
        appliedCancellationPolicy: {
          cancellationPolicyId: true,
        },
      },
      protection: {
        status: true,
        orderId: true,
        price: true,
        time: true,
      },
      otaBookingInfo: {
        ota: true,
        otaBookingId: true,
        channel: true,
        channelBookingId: true,
        channelAccount: {
          channelId: true,
          name: true,
        },
      },
      relocations: true,
      hotelRoomType: {
        hotelId: true,
        roomKind: true,
        id: true,
      },
    })
    .then(async ({ data, errors }) => {
      if (errors?.length) {
        throw new Error(errors[errors?.length - 1]?.message)
      }

      const home = await getBookingHomeInfo({ bookingId, hash, confirmationId })

      return { ...data, home }
    })
}

export const previewAlteration = async ({
  bookingId,
  feeOverrides,
  newHomeId,
  newRange,
  nightPrices = [],
  taxExemption,
  queryAlias,
}: Partial<PreviewAlterDatesVariables>) => {
  return arriereBackoffice.queries.booking.raw({
    __alias: queryAlias || 'previewAlteration',
    __args: {
      bookingId,
    },
    previewAlteration: {
      __args: {
        nightPrices,
        feeOverrides,
        newHomeId,
        newRange,
        taxExemption,
      },
      taxes: {
        id: true,
        name: true,
        amount: true,
      },
      fees: {
        id: true,
        name: true,
        amount: true,
        feeCalculationType: true,
      },
      nights: {
        date: true,
        price: true,
      },
      feesBreakdown: {
        actual: {
          id: true,
          itemId: true,
          name: true,
          amount: true,
          feeCalculationType: true,
        },
        modified: {
          id: true,
          itemId: true,
          amountChange: {
            from: true,
            to: true,
          },
          feeCalculationType: true,
          name: true,
        },
        added: {
          id: true,
          itemId: true,
          name: true,
          amount: true,
          feeCalculationType: true,
        },
        nonModified: {
          id: true,
          itemId: true,
          name: true,
          amount: true,
          feeCalculationType: true,
        },
      },
      items: {
        id: true,
        kind: true,
        title: true,
        total: true,
        date: true,
        details: {
          serviceRequestId: true,
          date: true,
          dateTime: true,
          time: true,
          dateRange: {
            start: true,
            end: true,
          },
          quantity: true,
          items: true,
        },
        adjustment: {
          amount: true,
          reason: true,
        },
        cancellation: {
          reason: true,
          time: true,
        },
        createdAt: true,
        balance: true,
      },
      newPrice: true,
      newSummary: {
        valueNightsAndFees: true,
        balance: true,
        deposit: {
          balance: true,
        },
        isPaid: true,
        paid: {
          credits: true,
          total: true,
        },
        paymentBalanceStatus: true,
        total: true,
        value: true,
      },
    },
  })
}

export const getOTAFolio = async (bookingId: string) => {
  return arriereBackoffice.queries.distribution
    .raw({
      __alias: 'getOTAFolio',
      booking: {
        __args: {
          id: bookingId,
        },
        getFolio: {
          items: {
            bookingItemId: true,
            date: true,
            feeId: true,
            kind: true,
            taxId: true,
            title: true,
            total: true,
          },
          appliedDiscounts: {
            bookingItemId: true,
            date: true,
            feeId: true,
            kind: true,
            taxId: true,
            title: true,
            total: true,
          },
          summary: {
            hostFee: true,
            hostFeeBase: true,
            hostFeeVat: true,
            payout: true,
            salesOrder: true,
            subTotal: true,
          },
        },
      },
    })
    .then(({ data, errors }) => {
      if (errors?.length) {
        throw new Error(errors[errors?.length - 1].message)
      }

      return data.booking.getFolio
    })
}

export const getBookingItems = async (bookingId: string) => {
  return arriereBackoffice.queries
    .booking({
      __alias: 'getBookingItems',
      __args: {
        bookingId,
      },
      folio: {
        items: {
          paymentId: true,
          id: true,
          kind: true,
          title: true,
          total: true,
          date: true,
          balance: true,
          note: true,
          createdAt: true,
          details: {
            serviceRequestId: true,
            date: true,
            dateTime: true,
            time: true,
            dateRange: {
              start: true,
              end: true,
            },
            quantity: true,
            items: true,
          },
          adjustment: {
            amount: true,
            reason: true,
          },
          cancellation: {
            reason: true,
            time: true,
          },
          request: {
            status: true,
            amounts: {
              requested: true,
              approved: true,
              refundedAt: true,
              refundSource: {
                cardLast4: true,
                credits: true,
                bankLast4: true,
                platform: true,
                affirm: true,
                manual: true,
                processType: true,
              },
            },
            statesHistory: {
              status: true,
              datetime: true,
              author: {
                name: true,
              },
              note: true,
            },
          },
        },
      },
    })
    .then(response => response?.folio?.items)
}

export const getBookingFolioInfo = async (bookingId: string) => {
  return arriereBackoffice.queries
    .booking({
      __alias: 'getBookingFolioInfo',
      __args: {
        bookingId,
      },
      folio: {
        summary: {
          valueNightsAndFees: true,
          total: true,
          balance: true,
          isPaid: true,
          paymentBalanceStatus: true,
          paid: {
            total: true,
            credits: true,
          },
          value: true,
          deposit: {
            balance: true,
          },
        },
        items: {
          id: true,
          kind: true,
          title: true,
          total: true,
          date: true,
          note: true,
          details: {
            serviceRequestId: true,
            date: true,
            dateTime: true,
            time: true,
            dateRange: {
              start: true,
              end: true,
            },
            quantity: true,
            items: true,
          },
          adjustment: {
            amount: true,
            reason: true,
          },
          cancellation: {
            reason: true,
            time: true,
          },
          createdAt: true,
          balance: true,
        },
      },
    })
    .then(response => response?.folio)
}

export const cancelBookingWithRefunds = async ({
  bookingId,
  reason,
  refundStayCycle,
  note,
  policyId,
  refundsBySource,
  refundsByService,
  cancellationSource,
}: CancelBookingWithRefundsProps) => {
  return arriereBackoffice.mutations.cancelBookingWithRefundsV2.raw({
    __alias: 'cancelBookingWithRefundsV2',
    __args: {
      bookingId,
      reason,
      note,
      policyId,
      refundStayCycle,
      refundsBySource,
      refundsByService,
      cancellationSource,
    },
  })
}

export const alterBookingRoomType = async (bookingId: string, homeId: string) => {
  return arriereBackoffice.mutations.booking.raw({
    __alias: 'alterBookingRoomType',
    alterHotelRoom: {
      __args: { bookingId, homeId },
    },
  })
}
