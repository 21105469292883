import arriereBackoffice from '@avantstay/arriere-clients/dist/arriereBackoffice'

import { PreBookingFloorPriceInput, PreBookingPriceInput } from '@arriere/types/BookingPrices.types'

export const getHomeDataForQuotes = async (homeId: string) => {
  return arriereBackoffice.queries.home
    .raw({
      __alias: 'getHomeDataForQuote',
      __args: { homeId },
      name: true,
      title: true,
      id: true,
      maxPets: true,
      maxOccupancy: true,
      halfBathroomsCount: true,
      bathroomsCount: true,
      bedroomsCount: true,
      hasPool: true,
      bedsCount: true,
      area: true,
      images: {
        description: true,
        asset: {
          url: true,
        },
        listOrder: true,
      },
      address: {
        city: true,
      },
      region: {
        id: true,
        name: true,
      },
      state: {
        code: true,
      },
      thumbnail: {
        url: true,
      },
      rooms: {
        sleepingPlaces: {
          amenity: {
            name: true,
            id: true,
          },
          quantity: true,
        },
      },
    })
    .then(({ data, errors }) => {
      if (errors?.length) {
        throw Error(errors[0]?.message)
      }

      return {
        ...data,
        rooms: data.rooms.map(room => ({
          sleepingPlaces: room.sleepingPlaces.map(sleepingPlace => ({
            name: sleepingPlace.amenity.name,
            amenityId: sleepingPlace.amenity.id,
            quantity: sleepingPlace.quantity,
          })),
        })),
      }
    })
}

export const getPreBookingPriceBreakdown = async ({
  homeId,
  from,
  until,
  guests,
  pets,
  pricingOverrides,
  reservationType,
  alias,
}: PreBookingPriceInput & { alias?: string }) => {
  return arriereBackoffice.queries.preBookingPriceBreakdown
    .raw({
      __alias: alias || 'getPreBookingPriceBreakdown',
      __args: { input: { homeId, from, until, guests, pets, pricingOverrides, reservationType } },
      detailedView: {
        reservationType: {
          id: true,
          isRevenueGenerating: true,
        },
        nightsPrices: {
          all: {
            date: true,
            calculatedPrice: {
              price: true,
              priceDiscount: {
                discount: true,
              },
            },
            fallbackPrice: true,
          },
        },
        taxesAndFees: {
          taxFeeId: true,
          kind: true,
          name: true,
          amount: true,
          appliedDiscount: true,
        },
        servicesPrices: {
          kind: true,
          fee: true,
          deposit: true,
          serviceId: true,
          total: true,
        },
        deposits: {
          security: true,
          services: true,
          total: true,
        },
        longTermDiscount: {
          total: true,
          discount: true,
          discountPercents: true,
        },
        subtotal: {
          total: true,
          discount: true,
          totalDiscounted: true,
        },
        total: {
          subtotal: true,
          deposits: true,
          total: true,
        },
      },
      guestView: {
        preBookingTotalBeforeTaxes: {
          withDiscount: true,
        },
      },
    })
    .then(({ data, errors, warnings }) => {
      const getNightAvailablePrice = (price?: number | null, fallbackPrice?: number | null) => {
        if (price != null) return price
        if (price == null && fallbackPrice != null) return fallbackPrice

        return undefined
      }

      return {
        data: {
          ...data?.detailedView,
          guestView: {
            ...data?.guestView,
          },
          nights: data?.detailedView?.nightsPrices?.all?.map(night => {
            return {
              date: night.date,
              price: getNightAvailablePrice(night.calculatedPrice?.price, night?.fallbackPrice),
              discountApplied: night.calculatedPrice?.priceDiscount?.discount,
            }
          }),
        },
        errors,
        warnings,
      }
    })
}

export const getTaxFeeDiscountsIds = async () => {
  return arriereBackoffice.queries.taxFeeDiscounts
    .raw({
      __alias: 'getTaxFeeDiscountsIds',
      id: true,
      name: true,
      value: true,
    })
    .then(({ data, errors }) => {
      if (errors?.length) {
        throw Error(errors[0]?.message)
      }

      return data
    })
}

export const getPreBookingPriceTotal = async ({
  homeId,
  from,
  until,
  guests,
  pets,
  pricingOverrides,
}: PreBookingPriceInput) => {
  return arriereBackoffice.queries.preBookingPriceBreakdown
    .raw({
      __alias: 'getPreBookingPriceTotal',
      __args: { input: { homeId, from, until, guests, pets, pricingOverrides } },
      detailedView: {
        total: {
          subtotal: true,
          deposits: true,
          total: true,
        },
      },
    })
    .then(({ data, errors }) => {
      if (errors?.length) {
        throw new Error(errors[errors?.length - 1]?.message)
      }

      return data.detailedView
    })
}

export const getPreBookingFloorPrice = async ({
  homeId,
  from,
  until,
}: PreBookingFloorPriceInput) => {
  return arriereBackoffice.queries.preBookingFloorPrice
    .raw({
      __alias: 'getPreBookingFloorPrice',
      __args: { homeId, from, until },
      date: true,
      price: true,
    })
    .then(({ data, errors }) => {
      if (errors) {
        throw Error(errors[0]?.message)
      }

      return data
    })
}
